.markdown {
    @apply text-lg;
    @apply leading-relaxed;
  }
  
  .markdown p,
  .markdown ul,
  .markdown ol,
  .markdown blockquote {
    @apply my-6;
  }

  .markdown li {
    @apply list-disc;
    @apply ml-5;
  }

  .markdown h1 {
    @apply text-4xl; 
    @apply mt-5; 
    @apply mb-4;
    @apply leading-snug;
    @apply font-bold
  }
  
  .markdown h2 {
    @apply text-3xl; 
    @apply mt-12; 
    @apply mb-4;
    @apply leading-snug;
  }
  
  .markdown h3 {
    @apply text-2xl;
    @apply mt-8;
    @apply mb-4;
    @apply leading-snug;
  }